import React, { Component } from 'react';

import './index.scss';
import { AppStateContext } from 'src/contexts/app-state-provider.context';
import Layout from 'src/components/layout';
import { TranslatedBlock } from 'src/components/language';
import { TranslatableText } from '../../components/language';
import Disclaimer from '../../components/disclaimer';
import LearnMore from '../../components/learn-more';
import OurVisionCareBlackEN from '../../images/OurVisionCare_Final_RGB_EN.svg';
import HowCan1 from '../../images/how-can-1.svg';
import HowCan2 from '../../images/how-can-2.svg';
import HowCan3 from '../../images/how-can-3.svg';

class PatientPage extends Component {
  static contextType = AppStateContext;
  componentDidMount() {
    this.context.setSection('patient');
    this.context.setPage('ourvisioncare-program');
  }

  render() {
    const title = {
      english: 'Our VISION Care Program'
    };
    return (
      <Layout title={title} showNavbar={true} className="patient">
        <section className="page-header">
          <div className="container h-100">
            <div className="row h-100">
              <TranslatedBlock language="english">
                <div className="col-12 col-md-5 p-5 d-flex align-items-center">
                  <h1>
                    <img loading="lazy" src={OurVisionCareBlackEN} alt="Our Vision Care" className={'img-fluid'} />
                  </h1>
                </div>
                <div className="col-12 col-md-7 p-5">
                  <h2 className="h1 mb-4">What is a Patient Support Program?</h2>
                  <p>
                    Most Patient Support Programs are designed to help people get the medicine that their doctor has
                    prescribed. This can mean helping them understand whether they have coverage for medicines through
                    their insurance or other public programs or helping them find other financial support.
                  </p>
                </div>
              </TranslatedBlock>
            </div>
          </div>
        </section>

        <div className="container mb-5">
          <div className="row">
            <div className="col-12 py-5 text-center">
              <TranslatedBlock language="english">
                <h2 className="head-text">How can the Our VISION Care Program help me?</h2>
              </TranslatedBlock>
              <TranslatedBlock language="french">
                <h2 className="head-text">
                  Quels sont les services offerts dans le cadre du programme d’assistance aux patients Nos soins de la
                  VUE?
                </h2>
              </TranslatedBlock>
            </div>
            <div className="col-12 col-lg-4 pb-lg-0 pb-5 text-center sans-font">
              <img loading="lazy" src={HowCan1} alt="HowCan1" className={'mb-5'} />
              <p>
                <TranslatableText
                  dictionary={{
                    english:
                      'Helps you find out if you have coverage for VABYSMO and helps with all the paperwork that is needed'
                  }}
                />
              </p>
            </div>
            <div className="col-12 col-lg-4 pb-lg-0 pb-5 text-center">
              <img loading="lazy" src={HowCan2} alt="HowCan2" className={'mb-5'} />
              <p>
                <TranslatableText
                  dictionary={{
                    english: 'Provides answers to questions you may have about VABYSMO'
                  }}
                />
              </p>
            </div>
            <div className="col-12 col-lg-4 pb-lg-0 pb-5 text-center sans-font">
              <img loading="lazy" src={HowCan3} alt="HowCan3" className={'mb-5'} />
              <p>
                <TranslatableText
                  dictionary={{
                    english:
                      'Our VISION Care Program staff can use translation services to speak to you in the language that you prefer'
                  }}
                />
              </p>
            </div>
          </div>
        </div>

        <LearnMore />
        <Disclaimer />
      </Layout>
    );
  }
}

export default PatientPage;
